import request from "@/core/services/axios";

export function getExamwork(query) {
  return request({
    url: "/exam/exam/get/examwork",
    method: "get",
    params: query,
  });
}

// 考试分工获取 (联考)
export function getExamunionwork(query) {
  return request({
    url: "/exam/exam/get/examunionwork",
    method: "get",
    params: query,
  });
}

export function saveExamwork(data) {
  return request({
    url: "/exam/exam/save/examwork",
    method: "post",
    data: data,
  });
}

// 联考-考试分工查询老师
export function examunionteacher(data) {
  return request({
    url: "/exam/exam/query/examunionteacher",
    method: "post",
    data: data,
  });
}

// 联考-考务分工查询老师学校
export function unionExistschool(data) {
  return request({
    url: "/exam/exam/union/existschool",
    method: "get",
    params: data,
  });
}

// 联考-考试分工保存
export function saveExamunionwork(data) {
  return request({
    url: "/exam/exam/save/examunionwork",
    method: "post",
    data: data,
  });
}
